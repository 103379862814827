// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/var/lib/jenkins/workspace/lyn_clone/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/var/lib/jenkins/workspace/lyn_clone/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/var/lib/jenkins/workspace/lyn_clone/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("/var/lib/jenkins/workspace/lyn_clone/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-excerpt-js": () => import("/var/lib/jenkins/workspace/lyn_clone/src/pages/excerpt.js" /* webpackChunkName: "component---src-pages-excerpt-js" */),
  "component---src-pages-index-js": () => import("/var/lib/jenkins/workspace/lyn_clone/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-memoir-js": () => import("/var/lib/jenkins/workspace/lyn_clone/src/pages/memoir.js" /* webpackChunkName: "component---src-pages-memoir-js" */),
  "component---src-pages-photo-gallery-js": () => import("/var/lib/jenkins/workspace/lyn_clone/src/pages/photo-gallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/var/lib/jenkins/workspace/lyn_clone/.cache/data.json")

